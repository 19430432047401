import {
  AssetItemDto,
  AssetVersionCommentDto,
  AssetVersionCommentTimecodeDto,
  AssetVersionItemDto,
  AudioVideoMetadataDto,
  DocumentMetadataDto
} from '@api/Api';
import { saveAs } from 'file-saver';
import { formatDuration } from '@helpers/formatDuration';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';

const sharedCsvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: false
};

const videoCsvOptions = {
  ...sharedCsvOptions,
  headers: [
    'Workspace',
    'Campaign',
    'File',
    'File URL',
    'Version',
    'Commenter',
    'CommentID',
    'Comment',
    'Reply',
    'Commented At',
    'Annotation',
    'Duration',
    'Timecode In',
    'Timecode Out'
  ]
};

const audioCsvOptions = {
  ...sharedCsvOptions,
  headers: [
    'Workspace',
    'Campaign',
    'File',
    'File URL',
    'Version',
    'Commenter',
    'CommentID',
    'Comment',
    'Reply',
    'Commented At',
    'Duration',
    'Timecode In',
    'Timecode Out'
  ]
};

const imageCsvOptions = {
  ...sharedCsvOptions,
  headers: [
    'Workspace',
    'Campaign',
    'File',
    'File URL',
    'Version',
    'Commenter',
    'CommentID',
    'Comment',
    'Reply',
    'Commented At',
    'Annotation'
  ]
};

const documentCsvOptions = {
  ...sharedCsvOptions,
  headers: [
    'Workspace',
    'Campaign',
    'File',
    'File URL',
    'Version',
    'Commenter',
    'CommentID',
    'Comment',
    'Reply',
    'Commented At',
    'Annotation',
    'Page'
  ]
};

function commentToRawText({ mentions, text }: any) {
  let rawText = text.replace(/\n/g, '<br />');

  mentions.forEach((el: any) => {
    rawText = rawText.replace(
      `{{${el.id}}}`,
      `@${el.firstName} ${el.lastName}`
    );
  });
  return rawText;
}

function sorter(a: AssetVersionCommentDto, b: AssetVersionCommentDto) {
  const aSeconds = a.timeCode?.fromSeconds;
  const bSeconds = b.timeCode?.fromSeconds;

  if (aSeconds === 0 && bSeconds === 0) {
    return 0;
  }
  if (aSeconds === 0) {
    return -1;
  }
  if (bSeconds === 0) {
    return 1;
  }

  if (!aSeconds && !bSeconds) {
    return 0;
  }
  if (!aSeconds) {
    return 1;
  }
  if (!bSeconds) {
    return -1;
  }
  return aSeconds - bSeconds;
}

export const exportAsPlainText = (
  selectedVersion: AssetVersionItemDto,
  comments: AssetVersionCommentDto[]
) => {
  let content = ``;

  content = `${selectedVersion.name}.${selectedVersion.extension}\n`;
  content += `${selectedVersion.user?.name} - ${moment(
    selectedVersion.createdAt
  ).format('h:mma MMM DD, YYYY')}\n\n`;
  const _comments = comments.sort(sorter);

  _comments.forEach((comment, idx) => {
    let index: string | number = idx + 1;
    if ((index as number) < 10) index = `00${index}`;
    if ((index as number) > 9) index = `0${index}`;
    if ((index as number) > 99) index = `${index}`;

    content += `${index} - ${comment.user?.firstName} ${
      comment.user?.lastName
    } - ${moment(comment.updatedAt || comment.createdAt).format(
      'h:mma MMM DD, YYYY'
    )}\n`;
    const { children } = comment as any;
    const timeCode = formatTimeCode(
      comment.documentPage,
      comment.timeCode,
      selectedVersion.metadata.type
    );
    const annotations = formatAnnotations(comment.annotations);
    const text = commentToRawText({
      mentions: comment.mentions,
      text: comment.text
    });
    content += `${timeCode}${annotations}${text}\n\n`;

    children.forEach((childComment: any) => {
      content += `\t${childComment.user?.firstName} ${
        childComment.user?.lastName
      } - ${moment(comment.updatedAt || comment.createdAt).format(
        'h:mma MMM DD, YYYY'
      )}\n`;
      const annotations = formatAnnotations(childComment.annotations);
      const text = commentToRawText({
        mentions: childComment.mentions,
        text: childComment.text
      });
      content += `\t${annotations}${text}\n\n`;
    });
  });
  const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
  saveAs(blob, `${selectedVersion.name}.txt`);
};

export const exportAsCSV = (
  workspaceName: string,
  asset: AssetItemDto,
  selectedVersion: AssetVersionItemDto,
  comments: AssetVersionCommentDto[]
) => {
  const csvExporter = new ExportToCsv();
  csvExporter.options = {
    filename: selectedVersion.name
  };
  if (selectedVersion.metadata.type === 'video') {
    csvExporter.options = {
      ...csvExporter.options,
      ...videoCsvOptions
    };
    const metadata = selectedVersion.metadata as AudioVideoMetadataDto;
    const _comments = comments.sort(sorter);
    const data = _comments.map((it) => {
      return {
        workspace: workspaceName,
        campaign: asset.campaignName,
        file: selectedVersion.name,
        fileUrl: `${window.origin}/media-viewer/${asset.id}/${selectedVersion.id}`,
        version: selectedVersion.versionNumber,
        commenter: `${it.user?.firstName} ${it.user?.lastName}`,
        commentId: it.id,
        comment: commentToRawText({
          mentions: it.mentions,
          text: it.text
        }),
        reply: it.repliedToId ? 'yes' : 'no',
        commentedAt: moment(it.updatedAt || it.createdAt).format(
          'h:mma MMM DD YYYY'
        ),
        annotation: it.annotations ? 'yes' : 'no',
        duration: formatDuration(
          metadata.info.avInfo?.video?.durationSeconds || 0
        ),
        timecodeIn: it.timeCode ? formatDuration(it.timeCode.fromSeconds) : '',
        timecodeOut: it.timeCode
          ? it.timeCode.toSeconds
            ? formatDuration(it.timeCode.toSeconds)
            : formatDuration(it.timeCode.fromSeconds)
          : ''
      };
    });
    csvExporter.generateCsv(data);
  }
  if (selectedVersion.metadata.type === 'audio') {
    csvExporter.options = {
      ...csvExporter.options,
      ...audioCsvOptions
    };
    const metadata = selectedVersion.metadata as AudioVideoMetadataDto;
    const _comments = comments.sort(sorter);
    const data = _comments.map((it) => {
      return {
        workspace: workspaceName,
        campaign: asset.campaignName,
        file: selectedVersion.name,
        fileUrl: `${window.origin}/media-viewer/${asset.id}/${selectedVersion.id}`,
        version: selectedVersion.versionNumber,
        commenter: `${it.user?.firstName} ${it.user?.lastName}`,
        commentId: it.id,
        comment: commentToRawText({
          mentions: it.mentions,
          text: it.text
        }),
        reply: it.repliedToId ? 'yes' : 'no',
        commentedAt: moment(it.updatedAt || it.createdAt).format(
          'h:mma MMM DD YYYY'
        ),
        duration: formatDuration(
          metadata.info.avInfo?.video?.durationSeconds || 0
        ),
        timecodeIn: it.timeCode ? formatDuration(it.timeCode.fromSeconds) : '',
        timecodeOut: it.timeCode
          ? it.timeCode.toSeconds
            ? formatDuration(it.timeCode.toSeconds)
            : formatDuration(it.timeCode.fromSeconds)
          : ''
      };
    });
    csvExporter.generateCsv(data);
  }
  if (
    selectedVersion.metadata.type === 'raster_image' ||
    selectedVersion.metadata.type === 'vector_image' ||
    selectedVersion.metadata.type === 'camera_raw_image'
  ) {
    csvExporter.options = {
      ...csvExporter.options,
      ...imageCsvOptions
    };

    const data = comments.map((it) => {
      return {
        workspace: workspaceName,
        campaign: asset.campaignName,
        file: selectedVersion.name,
        fileUrl: `${window.origin}/media-viewer/${asset.id}/${selectedVersion.id}`,
        version: selectedVersion.versionNumber,
        commenter: `${it.user?.firstName} ${it.user?.lastName}`,
        commentId: it.id,
        comment: commentToRawText({
          mentions: it.mentions,
          text: it.text
        }),
        reply: it.repliedToId ? 'yes' : 'no',
        commentedAt: moment(it.updatedAt || it.createdAt).format(
          'h:mma MMM DD YYYY'
        ),
        annotation: it.annotations ? 'yes' : 'no'
      };
    });
    csvExporter.generateCsv(data);
  }
  if (
    selectedVersion.metadata.type === 'document' ||
    selectedVersion.metadata.type === 'presentation' ||
    selectedVersion.metadata.type === 'spreadsheet'
  ) {
    csvExporter.options = {
      ...csvExporter.options,
      ...documentCsvOptions
    };

    const data = comments.map((it) => {
      return {
        workspace: workspaceName,
        campaign: asset.campaignName,
        file: selectedVersion.name,
        fileUrl: `${window.origin}/media-viewer/${asset.id}/${selectedVersion.id}`,
        version: selectedVersion.versionNumber,
        commenter: `${it.user?.firstName} ${it.user?.lastName}`,
        commentId: it.id,
        comment: commentToRawText({
          mentions: it.mentions,
          text: it.text
        }),
        reply: it.repliedToId ? 'yes' : 'no',
        commentedAt: moment(it.updatedAt || it.createdAt).format(
          'h:mma MMM DD YYYY'
        ),
        annotation: it.annotations ? 'yes' : 'no',
        page: it.documentPage ?? ''
      };
    });
    csvExporter.generateCsv(data);
  }
};

export const exportAsXML = (
  workspaceName: string,
  asset: AssetItemDto,
  selectedVersion: AssetVersionItemDto,
  comments: AssetVersionCommentDto[]
) => {
  let xml = '<?xml version="1.0" encoding="utf-8"?>';
  const _comments = comments.sort(sorter);
  xml += `<cover><spaceInfo workspaceId="${asset.workspaceId}" campaignId="${
    asset.campaignId
  }" workspaceName="${workspaceName}" campaignName="${
    asset.campaignName
  }"/>            
  ${assetInfoXMLBuilder(asset, selectedVersion)}
  <comments count="${comments.length}">
    ${_comments.map((comment) => {
      const { user } = comment;
      const { children } = comment as any;
      return `
        <comment id="${comment.id}" createdAt="${
        comment.createdAt
      }" updatedAt="${comment.updatedAt}">
          <user id="${user?.id}" name="${user?.firstName} ${
        user?.lastName
      }" picture="${user?.picture?.url?.split('?')?.[0]}"/>
          <text content="${comment.text}"/>
          ${annotationInfoXMLBuilder(
            selectedVersion.metadata.type,
            comment.annotations
          )}
          
          ${
            children.length
              ? `<replies count="${children.length}">
          ${children.map((childComment: any) => {
            return `<reply id="${childComment.id}" createdAt="${
              comment.createdAt
            }" updatedAt="${comment.updatedAt}">
            <user id="${user?.id}" name="${childComment.user?.firstName} ${
              childComment.user?.lastName
            }" picture="${childComment.user?.picture?.url?.split('?')?.[0]}"/>
            <text content="${childComment.text}"/>
            ${annotationInfoXMLBuilder(
              selectedVersion.metadata.type,
              comment.annotations
            )}
            </reply>`;
          })}            
        </replies>`
              : ''
          }
        </comment>`;
    })}
  </comments>
  </cover>`;
  const blob = new Blob([xml], { type: 'application/xml;charset=utf-8,' });
  saveAs(blob, `${selectedVersion.name}.xml`);
};

const formatTimeCode = (
  documentPage: number | null | undefined,
  timeCode: AssetVersionCommentTimecodeDto | null | undefined,
  assetType:
    | 'video'
    | 'audio'
    | 'raster_image'
    | 'camera_raw_image'
    | 'vector_image'
    | 'document'
    | 'presentation'
    | 'spreadsheet'
) => {
  if (
    assetType === 'document' ||
    assetType === 'presentation' ||
    assetType === 'spreadsheet'
  ) {
    if (!documentPage) return '';
    return `Page ${documentPage} - `;
  }
  if (!timeCode) return '';

  const from = timeCode.fromSeconds.toString();
  const to = timeCode.toSeconds ? timeCode.toSeconds.toString() : null;

  if (from && !to) return `${formatDuration(+from)} - `;
  if (from && to)
    return `[${formatDuration(+from)} - ${formatDuration(+to)}] - `;
  return '';
};

const formatAnnotations = (annotations: string | null | undefined) =>
  annotations ? '[Annotation] - ' : '';

const assetInfoXMLBuilder = (
  asset: AssetItemDto,
  selectedVersion: AssetVersionItemDto
) => {
  if (selectedVersion.metadata.type === 'video') {
    const metadata = selectedVersion.metadata as AudioVideoMetadataDto;
    return `<assetInfo id="${selectedVersion.id}" name="${selectedVersion.name}" url="${window.origin}/media-viewer/${asset.id}/${selectedVersion.id}" extension="${selectedVersion.extension}" type="${selectedVersion.metadata.type}" version="${selectedVersion.versionNumber}" createdAt="${selectedVersion.createdAt}" duration="${metadata.info.avInfo?.video?.durationSeconds}"/>`;
  }
  if (selectedVersion.metadata.type === 'audio') {
    const metadata = selectedVersion.metadata as AudioVideoMetadataDto;
    return `<assetInfo id="${selectedVersion.id}" name="${selectedVersion.name}" url="${window.origin}/media-viewer/${asset.id}/${selectedVersion.id}" extension="${selectedVersion.extension}" type="${selectedVersion.metadata.type}" version="${selectedVersion.versionNumber}" createdAt="${selectedVersion.createdAt}" duration="${metadata.info.avInfo?.audio?.durationSeconds}"/>`;
  }
  if (
    selectedVersion.metadata.type === 'camera_raw_image' ||
    selectedVersion.metadata.type === 'raster_image' ||
    selectedVersion.metadata.type === 'vector_image'
  ) {
    return `<assetInfo id="${selectedVersion.id}" name="${selectedVersion.name}" url="${window.origin}/media-viewer/${asset.id}/${selectedVersion.id}" extension="${selectedVersion.extension}" type="${selectedVersion.metadata.type}" version="${selectedVersion.versionNumber}" createdAt="${selectedVersion.createdAt}"/>`;
  }
  const metadata = selectedVersion.metadata as DocumentMetadataDto;
  return `<assetInfo id="${selectedVersion.id}" name="${selectedVersion.name}" url="${window.origin}/media-viewer/${asset.id}/${selectedVersion.id}" extension="${selectedVersion.extension}" type="${selectedVersion.metadata.type}" version="${selectedVersion.versionNumber}" createdAt="${selectedVersion.createdAt}" pages="${metadata.info.documentInfo?.pageCount}"/>`;
};

const annotationInfoXMLBuilder = (
  assetType:
    | 'video'
    | 'audio'
    | 'raster_image'
    | 'camera_raw_image'
    | 'vector_image'
    | 'document'
    | 'presentation'
    | 'spreadsheet',
  annotations: string | null | undefined
) => {
  if (!annotations || assetType === 'audio') return '';
  return `<annotations>
  ${JSON.parse(annotations).map((it: any) => {
    const annotation = it;

    return `<annotation>
    ${Object.entries(annotation).map(([key, value]: any) => {
      if (key === 'points')
        return `<points> ${value.map(
          (el: any) => `<point x="${el.x}" y="${el.y}"/>`
        )}</points>`;
      return `<option key="${key}" value="${value}"/>`;
    })}
    </annotation>`;
  })}
  </annotations>`;
};
