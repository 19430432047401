export const parseStringWithLinksIntoHtmlLinks = (
  text: string,
  target: '_blank' | '_parent' | '_self' = '_blank'
): string => {
  if (!text) return '';
  const urlRegex =
    /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/g;
  const matches: any = text.match(urlRegex);
  let output = text;
  if (matches) {
    for (const match of matches) {
      output = output.replace(
        match,
        `<a href="${match}" target="${target}">${match}</a>`
      );
    }
  }
  return output;
};
