import { AssetVersionCommentDto } from '@api/Api';

type TCommentItem = AssetVersionCommentDto & {
  children?: TCommentItem[];
  level?: number;
};

export function formatCommentsTree(comments: AssetVersionCommentDto[]) {
  const allComments = comments
    .filter((x) => !x.repliedToId)
    .map((item: TCommentItem) => ({
      ...item,
      level: 1,
      children: comments
        .filter((z: TCommentItem) => z.topParentId === item.id)
        .map((child: TCommentItem) => ({
          ...child,
          level: 2,
          children: []
        }))
        .sort(
          (a: TCommentItem, b: TCommentItem) =>
            new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
        )
        .reverse()
    }));
  return allComments;
}
