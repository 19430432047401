import { useState } from 'react';

export function usePreviousInputValue() {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [prevValues, setPrevValues] = useState<string[]>([]);

  const onChangePrevValues = (value: string) => {
    setCurrentIndex((prev) => prev + 1);
    setPrevValues((prev) => [...prev, value]);
  };

  const onUndoPrevValues = (): string => {
    const newValue = prevValues.slice(0, currentIndex - 1);
    const str = newValue?.[newValue.length - 1];
    if (!str) {
      onResetPrevValues();
      return '';
    }
    setCurrentIndex((prev) => prev - 1);
    setPrevValues([...newValue]);
    return str;
  };

  const onResetPrevValues = () => {
    setCurrentIndex(0);
    setPrevValues([]);
  };

  return {
    onChangePrevValues,
    onUndoPrevValues,
    onResetPrevValues
  };
}
