import moment from 'moment';

moment.defineLocale('en-comment-time', {
  parentLocale: 'en',
  relativeTime: {
    past: '%s',
    s: 'now',
    ss: '%ss',
    m: '%dmin',
    mm: '%dmin',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    M: '%dm',
    MM: '%dm',
    y: '%dy',
    yy: '%dy'
  }
});

export default function commentShortTime(time: string) {
  return moment(time).locale('en-comment-time').fromNow();
}
