import React, { useState, useMemo, useEffect } from 'react';
import { Input, Button, Checkbox, Row, Col, Dropdown, Tooltip } from 'antd';

import { ReactComponent as DownArrowSvg } from '@assets/icons/arrow-down.svg';
import { ReactComponent as HelpSvg } from '@assets/icons/help.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as SearchSvg } from '@assets/icons/search.svg';

import { TaskBoardItemDto, MemberDto } from '@api/Api';

import './CommentTask.less';
import UserAvatar from '@components/UserAvatar';

function CommentTask({
  selectedUser,
  setSelectedUser,
  selectedBoard,
  setSelectedBoard,
  setIsShowTask,
  isShowTask,
  potentialAssignees,
  boardList,
  canCreateTask
}: any) {
  const [localUsers, setLocalUsers] = useState<MemberDto[]>([]);
  const hasUsers = Array.isArray(localUsers) && localUsers.length !== 0;
  const isInviteUserByEmail = typeof selectedUser === 'string';

  const [inputValue, setInputValue] = useState<string>('');
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText: string = e.target.value;
    setInputValue(searchText);
  };

  const onSearchUser = () => {
    if (potentialAssignees) {
      if (inputValue) {
        const userFind: MemberDto[] = potentialAssignees.filter(
          (item: MemberDto) => {
            const _searchText = inputValue.toLocaleLowerCase();
            const { user } = item;
            if (user) {
              const checkEmail = user.email
                ? user?.email.toLocaleLowerCase().indexOf(_searchText) > -1
                : false;
              const checkName = user.name
                ? user?.name.toLocaleLowerCase().indexOf(_searchText) > -1
                : false;
              return checkEmail || checkName;
            }
            return false;
          }
        );
        setLocalUsers(inputValue ? userFind : potentialAssignees);
        return;
      }
      setLocalUsers(potentialAssignees);
    }
  };

  useEffect(() => {
    onSearchUser();
  }, [inputValue]);

  useEffect(() => {
    return () => {
      setSelectedUser(null);
      setSelectedBoard(null);
      setIsShowTask(false);
    };
  }, []);

  const onSelectUser = (user: MemberDto | null | string) => {
    setInputValue('');
    setSelectedUser(user);
  };

  const boardMenuItems = useMemo(
    () =>
      boardList.map((item: TaskBoardItemDto) => ({
        label: item.name,
        key: item.id,
        onClick: () => {
          setSelectedBoard(item.id === selectedBoard?.id ? null : item);
        }
      })),
    [boardList, selectedBoard]
  );

  const usersMenuItems = useMemo(
    () =>
      hasUsers &&
      localUsers.map((item: MemberDto) => ({
        label: (
          <Row wrap={false} gutter={16} align="middle">
            <Col>
              <UserAvatar
                isActive={item.isActive}
                size="large"
                src={item.user?.picture.url || ''}
                userEmail={item.user?.email || ''}
                userName={item.user?.name || ''}
              />
            </Col>
            <Col flex="auto">
              <div className="media_viewer_comment_invite-name">
                {item.user?.name}
              </div>
              <div className="media_viewer_comment_invite-email">
                {item.user?.email || item.invitationEmail}
              </div>
            </Col>
          </Row>
        ),
        key: item.id,
        onClick: () => {
          onSelectUser(item);
        }
      })),
    [localUsers, hasUsers]
  );

  return (
    <div className="media_viewer_comment_task">
      {canCreateTask && (
        <div className="media_viewer_comment_task-checkbox">
          <Checkbox
            checked={isShowTask}
            className="rich_text_form_checkbox"
            onChange={(e) => {
              setIsShowTask(!isShowTask);
            }}
          >
            <div className="media_viewer_comment_task-checkbox-copy">
              Add as a task
              <Tooltip
                placement="bottomRight"
                overlayClassName="media_viewer_tooltip"
                title="Check this box to turn the comment into a task and make it actionable."
              >
                <HelpSvg className="b-icon-help" />
              </Tooltip>
            </div>
          </Checkbox>
        </div>
      )}
      {isShowTask && (
        <Row
          wrap={false}
          gutter={8}
          className="media_viewer_comment_task-bottom"
        >
          <Col>
            {selectedUser ? (
              <div
                className={`media_viewer_comment_task-user ${
                  isInviteUserByEmail ? 'b-invite-user' : ''
                }`}
              >
                <UserAvatar
                  className="media_viewer_comment_task-user-avatar"
                  isActive={!isInviteUserByEmail}
                  size="small"
                  src={selectedUser?.user?.picture.url || ''}
                  userEmail={selectedUser?.user?.email || ''}
                  userName={selectedUser?.user?.name}
                />
                <div className="media_viewer_comment_task-user-name">
                  {isInviteUserByEmail
                    ? selectedUser
                    : selectedUser?.user?.name || selectedUser?.user?.email}
                </div>
                <button
                  type="button"
                  className="media_viewer_comment_task-user-btn"
                  onClick={() => setSelectedUser(null)}
                >
                  <CrossSvg />
                </button>
              </div>
            ) : (
              <Dropdown
                menu={{
                  items: usersMenuItems || [],
                  prefixCls: 'media_viewer_menu-menu'
                }}
                dropdownRender={(menu) => {
                  return (
                    <>
                      <div
                        className="media_viewer_comment_invite-search"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Input
                          suffix={<SearchSvg />}
                          className="media_viewer_comment_invite-input"
                          placeholder="Search user"
                          onChange={onSearch}
                        />
                      </div>
                      {menu}
                    </>
                  );
                }}
                overlayClassName="media_viewer_comment_invite"
                prefixCls="media_viewer_menu"
                placement="bottom"
                trigger={['click']}
              >
                <Button className="media_viewer_btn" htmlType="button">
                  <PlusSvg />
                  Assign to task
                </Button>
              </Dropdown>
            )}
          </Col>
          {boardList?.length !== 0 && (
            <Col>
              <Dropdown
                menu={{
                  selectedKeys: selectedBoard?.id,
                  items: [
                    {
                      type: 'group',
                      label: 'Add task Group',
                      children: boardMenuItems
                    }
                  ]
                }}
                prefixCls="media_viewer_menu"
                placement="bottom"
                trigger={['click']}
              >
                {selectedBoard?.name ? (
                  <Button
                    className="media_viewer_btn b-board-selected"
                    htmlType="button"
                  >
                    {selectedBoard?.name}
                    <DownArrowSvg />
                  </Button>
                ) : (
                  <Button className="media_viewer_btn" htmlType="button">
                    <PlusSvg />
                    Task Group
                  </Button>
                )}
              </Dropdown>
            </Col>
          )}
        </Row>
      )}
    </div>
  );
}

export default CommentTask;
